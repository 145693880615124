<template>
  <div class="advisory">
    <div style="max-width: 100%; margin-left: auto; margin-right: auto">
      <iframe
        src="https://www.easy-insight.com/a/dashboard/AkgPgXmLamnxZyg/embed"
        style="width: 100%; height: 100vh"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "Advisory",
  components: {},
};
</script>
